import './App.css';
import Hituji from "./components/Hituji";

function App() {
  return (
    <div className="App">
      <div>Hello World!</div>
      <Hituji />
    </div>
  );
}

export default App;
